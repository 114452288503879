import { Button, Form, Select } from "antd";
import React from "react";
import "../../../node_modules/antd/dist/antd.min.css";
import "../../../src/style.css";

const { Option } = Select;

export default function CardNivAccess({
  onFinish,
  onFinishFailed,
  userslist,
  setButtonPopup,
  handleChangeCol,
}) {
  const onReset = () => {
    form.resetFields();
    setButtonPopup(false);
  };

  const [form] = Form.useForm();

  return (
    <main className="popup">
      <div className="popup-inner">
        <div className="card01">
          <h4>Changer le Niveau accés</h4>
          <div className="card11 flex">
            <Form
              name="reinitmdp"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="userid"
                label="Utilisateur"
                style={{
                  margin: "0.5rem",
                }}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select a name"
                  // onChange={onSearch}
                  style={{ width: 200, marginRight: 10 }}
                >
                  {userslist.map((val, index) => (
                    <Select.Option key={index} value={val.id}>
                      {val.lastname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="access"
                label="Niveau"
                style={{
                  margin: "0.5rem",
                }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select a level"
                  onChange={handleChangeCol}
                  style={{ width: 200, marginRight: 10 }}
                >
                  <Option value="0">Accès limité</Option>
                  <Option value="1">Utilisateurs Serres Castet</Option>
                  <Option value="2">Utilisateurs Autres</Option>
                  <Option value="3">Tech. Mise en service</Option>
                  <Option value="4">Encadrement</Option>
                  <Option value="5">Administrateur</Option>
                </Select>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Valider
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="close-btn">
            <Form.Item>
              <Button htmlType="button" size="small" onClick={onReset}>
                X
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </main>
  );
}
