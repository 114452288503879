import { Button, Form, Select } from "antd";
import React from "react";
import "../../../node_modules/antd/dist/antd.min.css";
import "../../../src/style.css";

export default function CardReinitMdp({
  onFinish,
  onFinishFailed,
  setButtonPopup,
  userslist,
}) {
  const onReset = () => {
    form.resetFields();
    setButtonPopup(false);
  };

  const [form] = Form.useForm();

  return (
    <main className="popup">
      <div className="popup-inner">
        <div className="card01">
          <h4>Réinitialiser MDP</h4>
          <div className="card11 flex">
            <Form
              name="mdp"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="useridmdp"
                label="Utilisateur"
                style={{
                  margin: "0.5rem",
                }}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select a name"
                  // onChange={onSearch}
                  style={{ width: 200, marginRight: 10 }}
                >
                  {userslist.map((val, index) => (
                    <Select.Option key={index} value={val.id}>
                      {val.lastname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Réinitialiser
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="close-btn">
            <Form.Item>
              <Button htmlType="button" size="small" onClick={onReset}>
                X
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </main>
  );
}
