import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Admin from "./components/Admin/Admin";
import Clearances from "./components/Clearances/Clearances";
import Codes from "./components/Codes/Codes";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Password from "./components/Password/Password";
import Profile from "./components/Profile/Profile";
import Site from "./components/Site/Site";
import Tools from "./components/Tools/Tools";

export default function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tools" element={<Tools />} />
        <Route path="/clearances" element={<Clearances />} />
        <Route path="/codes" element={<Codes />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/password" element={<Password />} />
        <Route path="/site" element={<Site />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}
