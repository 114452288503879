import React, { createContext, useState } from "react";

export const AdminContext = createContext(null);

const AdminProvider = (props) => {
  const [adminpage, setAdminPage] = useState("");
  const [access, setAccess] = useState("");
  const [username, setUserName] = useState("");
  const [idlogged, setIdLogged] = useState("");
  const [loading, setLoading] = useState("");

  return (
    <AdminContext.Provider
      value={{
        adminpage,
        setAdminPage,
        username,
        setUserName,
        access,
        setAccess,
        idlogged,
        setIdLogged,
        loading,
        setLoading,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
