import React from "react";
import "../../../src/Popup.css";
import { Button, Form, Input, Space } from "antd";

import { UserOutlined } from "@ant-design/icons";

export default function CardMdp({
  chgPassword,
  onFinishFailed,
  username,
  onReset,
}) {
  const [form] = Form.useForm();

  return (
    <div className="popup">
      <div className="popup-inner">
        <div className="card01">
          <h4>CHANGER MOT DE PASSE</h4>
          <div className="card11 flex">
            <Form
              name="current-password"
              style={{
                width: 280,
              }}
              form={form}
              onFinish={chgPassword}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item label="Nom" name="lastname">
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={username}
                  value={username}
                  disabled
                />
              </Form.Item>
              <Form.Item
                label="Mot de passe"
                name="newpassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your new-password!",
                  },
                ]}
              >
                <Input.Password placeholder="new password" type="password" />
              </Form.Item>
              <Form.Item>
                <Space wrap>
                  <Button type="primary" htmlType="submit">
                    Modifier
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
          <div className="close-btn">
            <Form.Item>
              <Button htmlType="button" size="small" onClick={onReset}>
                X
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
}
