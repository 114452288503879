import React from "react";
import "../../../src/Popup.css";
import { Button, Form, DatePicker, Space } from "antd";

function PopupDate({ onFinishFailed, onReset, onFinish }) {
  const [form] = Form.useForm();
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

  return (
    <div className="popup">
      <div className="popup-inner">
        <div className="card01">
          <h4>MODIFIER DATE DE VALIDITE</h4>
          <div className="card11 flex">
            <Form
              name="basic"
              style={{
                width: 320,
              }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Fin de Validité"
                name="valid"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker format={dateFormatList} />
              </Form.Item>
              <Form.Item>
                <Space wrap>
                  <Button type="primary" htmlType="submit">
                    Modifier
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
          <div className="close-btn">
            <Form.Item>
              <Space>
                <Button htmlType="button" size="small" onClick={onReset}>
                  X
                </Button>
              </Space>
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PopupDate;
