import { Button, Form } from "antd";
import React from "react";
import "../../../src/Popup.css";

import moment from "moment";

function CardInfo({ valitem, onReset, loanlist }) {
  var valid = valitem.rev;
  var dateLimit = moment(valid);
  return (
    <div className="popup">
      <div className="popup-inner-info">
        <div className="card text-primary">
          <h1>{valitem.spie}</h1>
        </div>
        <div className="card text-primary flex">
          <div className="card1 auto">
            <h2 className="h-3">Marque:</h2>
            <h3 className="h-1">Type:</h3>
            <h3 className="h-1">Désignation:</h3>
            <h3 className="h-1">Observations:</h3>
            <h3 className="h-1">N° de série:</h3>
            <h3 className="h-1">Révision:</h3>
            <h3 className="h-1">Attribué à:</h3>
          </div>
          <div className="card1 auto">
            <h2 className="h-3">{valitem.num ?? "-"}</h2>
            <h3 className="h-1">{valitem.ref ?? "-"}</h3>
            <h3 className="h-1">{valitem.design ?? "-"}</h3>
            <h3 className="h-1">{valitem.obs ?? "-"}</h3>
            <h3 className="h-1">{valitem.sn ?? "-"}</h3>
            <span>
              {<h3 className="h-1">{dateLimit.format("DD-MM-YYYY")}</h3> ?? "-"}
            </span>
            <h3 className="h-1">{valitem.userid ?? "-"}</h3>
          </div>
        </div>
        <div className="card text-primary">
          <h2>MOUVEMENTS STOCK</h2>
          {loanlist
            .filter((value) => {
              if (valitem.id === value.outils_id) {
                return value;
              }
              return false;
            })
            .map((value, i, row) => {
              if (i + 1 === row.length) {
                return (
                  <div className="flex bg" key={value.id}>
                    <h3>{value.users_id}</h3>
                    <h3>&nbsp;&nbsp;</h3>
                    <h3>{value.obs}</h3>
                    <h3>&nbsp;&nbsp;</h3>
                    {value.recep === "" ? (
                      <h3>(Attente retour)</h3>
                    ) : (
                      <h3>{value.recep}</h3>
                    )}
                  </div>
                );
              }
              return null;
            })}
        </div>
        <div className="close-btn">
          <Form.Item>
            <Button htmlType="button" size="small" onClick={onReset}>
              FERMER
            </Button>
          </Form.Item>
        </div>
      </div>
    </div>
  );
}

export default CardInfo;
