import { Button, Form, Select, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "../../../src/style.css";
import { Axios } from "../../config";
import CardAddMdp from "./CardAddMdp";

import { AdminContext } from "../../Context/AdminContext";
import NotyfContext from "../../Context/NotyfContext";

import LoadAnim from "../LoadAnim";

const { Option } = Select;

export default function Password() {
  const { access, loading, setLoading } = useContext(AdminContext);
  const notyf = useContext(NotyfContext);

  const [passwordadd, setPasswordAdd] = useState("");
  const [password, setPassword] = useState([]);
  const [searchcol, setSearchcol] = useState("");
  const [company, setCompany] = useState([]);
  const [companylist, setCompanyList] = useState([]);
  const [buttonpopupCardAddMdp, setButtonPopupCardAddMdp] = useState(false);
  const [updatecde, setUpdateCde] = useState("");
  const [valitem, setValItem] = useState("");

  useEffect(() => {
    setLoading(false);
  });

  useEffect(() => {
    Axios.get("/api/get_company").then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setCompanyList(response.data);
      }
    });

    Axios.get("api/get_password").then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setPassword(response.data);
      }
    });

    setUpdateCde(0);
    setLoading(true);
  }, [updatecde, setLoading, notyf]);

  async function onFinishCardAddMdp(values) {
    await Axios.post("/api/insertmdp", {
      marque: values.marque,
      appareil: values.appareil,
      type: values.type,
      iduser: values.iduser,
      password: values.passwordadd,
      site: values.site,
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setPasswordAdd([
          ...passwordadd,
          {
            marque: values.marque,
            appareil: values.appareil,
            type: values.type,
            iduser: values.iduser,
            password: values.password,
            site: values.site,
          },
        ]);
        setUpdateCde("1");
        setButtonPopupCardAddMdp(false);
        form.resetFields();
        notyf.success(response.data.message);
      }
    });
  }

  const handleChangeCol = (value) => {
    setSearchcol(value);
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const btAjout = (val) => {
    const company = companylist.sort((a, b) => (a.num > b.num ? 1 : -1));
    setCompany(company);
    setButtonPopupCardAddMdp(true);
    setValItem(val);
  };

  const onFinishFailed = (errorFields) => {
    if (errorFields) {
      notyf.open({ type: "error", message: `${errorFields}` });
    }
  };

  const [form] = Form.useForm();

  return (
    <main className="main article">
      <Form {...layout} name="control-ref" form={form}>
        <div className="container flex admin">
          <div className="card01 m-2">
            <h4><b>LISTE DES MOTS DE PASSE</b></h4>
            {loading === true ? (
              <LoadAnim size="large" />
            ) : (
              <div className="card2 flex">
                {/* <Form.Item
                  label="Sélectionner une option de tri"
                  style={{
                    margin: "0.5rem",
                  }}
                >
                  <Select
                    placeholder="Select By:"
                    onChange={handleChangeCol}
                    style={{ width: 250, marginRight: 10 }}
                  >
                    <Option value="tous">TOUS...</Option>
                    <Option value="site">Site</Option>
                  </Select>
                </Form.Item> */}
                {access > 2 ? (
                  <Space wrap>
                    <Button
                      onClick={() => {
                        btAjout(valitem);
                      }}
                    >
                      Ajout Mot de passe
                    </Button>
                  </Space>
                ) : (
                  <></>
                )}
              </div>
            )}
            <div className="card1 flex app">
              <ul>
                <li className="flex">
                  <div className="mx-1 w-1 smb">MARQUE</div>
                  <div className="mx-1 w-1 smb">APPAREIL</div>
                  <div className="mx-1 w-1 smb">TYPE</div>
                  <div className="mx-1 w-1 smb">UTILISATEUR</div>
                  <div className="mx-1 w-1 smb">MOT DE PASSE</div>
                  <div className="mx-1 w-1 smb">SITE</div>
                </li>
              </ul>
              {password
                // .filter((val) => {
                //   if (searchcol === "site" && password.includes(val.site))
                //     if (password !== "") {
                //       return val;
                //     }
                //   if (searchcol === "tous") {
                //     return val;
                //   }
                //   return false;
                // })
                .sort((a, b) => (a.marque > b.marque ? 1 : -1))
                .map((val, index) => {
                  return (
                    <div className="card1 flex app" key={index}>
                      <ul className="mb-0">
                        <li className="flex bor-1">
                          <div className="mx-1 w-1">{val.marque}</div>
                          <div className="mx-1 w-1">{val.appareil}</div>
                          <div className="mx-1 w-1">{val.type}</div>
                          <div className="mx-1 w-1">{val.iduser}</div>
                          <div className="mx-1 w-1">{val.password}</div>
                          <div className="mx-1 w-1">{val.site}</div>
                        </li>
                      </ul>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Form>
      {buttonpopupCardAddMdp === true ? (
        <CardAddMdp
          setPassword={setPasswordAdd}
          // onNumChange={onNumChange}
          onFinish={onFinishCardAddMdp}
          onFinishFailed={onFinishFailed}
          company={company}
          setButtonPopup={setButtonPopupCardAddMdp}
        />
      ) : (
        <></>
      )}
    </main>
  );
}
