import React from "react";
import "../../../src/style.css";

function Site() {
  return (
    <main className="main article">
      <div className="container flex admin">
        <div className="card01 flex m-2">
          <ul className="mb-0 w-5">
            <li className="openFirstLevel">
              <a
                role="button"
                className="openFirstLevelButton"
                aria-expanded="false"
                href="https://bassin-lacq.accueil-secu.fr/login"
              >
                <h4>SOBEGI / ARKEMA (Bassin-lacq.accueil)</h4>
              </a>
            </li>
          </ul>
        </div>
        <div className="card01">
          <ul className="mb-0 w-5">
            <li className="openFirstLevel">
              <a
                role="button"
                className="openFirstLevelButton"
                aria-expanded="false"
                href="https://live.runmyprocess.com/pub/112501509958900690/appli/380769?P_mode=LIVE"
              >
                <h4>TEREGA Lussagnet (Acceuil Sécurité)</h4>
              </a>
            </li>
          </ul>
        </div>
        <div className="card01">
          <ul className="mb-0 w-5">
            <li className="openFirstLevel">
              <a
                role="button"
                className="openFirstLevelButton"
                aria-expanded="false"
                href="https://esafety-prod.airliquide.com/auth/login"
              >
                <h4>AIR LIQUIDE (E.Safety Tous sites)</h4>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
}
export default Site;
