import { Button, Form, Space } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import "../../../src/style.css";
import PopupDate from "./PopupDate";

import { AdminContext } from "../../Context/AdminContext";

export default function CardAttestList({
  attest,
  btupdateValid,
  btdeleteAttest,
  onFinishFailed,
  setUpdateValid,
  setAttestId,
  access,
}) {
  const [buttonpopupdate, setButtonPopupDate] = useState(false);

  const { username } = useContext(AdminContext);

  const [form] = Form.useForm();

  const buttondate = (val) => {
    setButtonPopupDate(true);
    setAttestId(val);
  };
  const onReset = () => {
    form.resetFields();
    setButtonPopupDate(false);
  };

  const btupdateValidform = (data) => {
    btupdateValid(data);
    setButtonPopupDate(false);
  };

  return (
    <div className="card01 m-2">
      <h4><b>LISTE DE VOS ATTESTATIONS</b></h4>
      <div className="card1 flex app">
        <ul>
          <li className="flex">
            <div className="mx-1 w-2 smb">DESIGNATION</div>
            <div className="mx-1 w-4 smb">TITRE / CLIENT</div>
            <div className="mx-1 w-1 smb">VALIDITE</div>
            <div className="mx-1">..</div>
            {access > 5 ? <div className="mx-2">..</div> : <></>}
          </li>
        </ul>
        <ul>
          {attest
          .sort((a, b) => (a.type > b.type ? 1 : -1))
          .map((val, index) => {
            var now = moment();
            var nowAlarm = moment().add(90, "d");
            var valid = val.valid;
            var dateLimit = moment(valid);
            return (
              <div key={index}>
                {val.userid === username ? (
                  <div className="card1 flex app">
                    <ul className="mb-0">
                      <li className="flex bor-1 pt-1">
                        <div className="mx-1 w-2">{val.type}</div>
                        <div className="mx-1 w-4">{val.data1}</div>
                        {dateLimit <= now ? (
                          <div className="mx-1 w-1 limitAlert">
                            {dateLimit.format("DD-MM-YYYY")}
                          </div>
                        ) : (
                          <></>
                        )}
                        {dateLimit >= now && dateLimit <= nowAlarm ? (
                          <div className="mx-1 w-1 limitAlarm">
                            {dateLimit.format("DD-MM-YYYY")}
                          </div>
                        ) : (
                          <></>
                        )}
                        {dateLimit >= nowAlarm ? (
                          <div className="mx-1 w-1 limit">
                            {dateLimit.format("DD-MM-YYYY")}
                          </div>
                        ) : (
                          <></>
                        )}
                        <Form>
                          <Space wrap>
                            <Button
                              type="primary"
                              size="small"
                              ghost
                              onClick={() => {
                                buttondate(val.id);
                              }}
                            >
                              Date
                            </Button>
                            {access > 5 ? (
                              <Button
                                size="small"
                                ghost
                                danger
                                onClick={() => {
                                  btdeleteAttest(val.id);
                                }}
                              >
                                Delete
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Space>
                        </Form>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </ul>
      </div>
      {buttonpopupdate === true ? (
        <PopupDate
          onReset={onReset}
          onFinishFailed={onFinishFailed}
          setUpdateValid={setUpdateValid}
          btupdateValid={btupdateValid}
          onFinish={btupdateValidform}
        ></PopupDate>
      ) : (
        <></>
      )}
    </div>
  );
}
