import { Form, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "../../../src/style.css";
import { Axios } from "../../config";

import moment from "moment";
import { AdminContext } from "../../Context/AdminContext";
import LoadAnim from "../LoadAnim";

// import cercle from '../../components/indiceh/indiceh'

const { Option } = Select;

function Clearances() {
  const { loading, setLoading } = useContext(AdminContext);
  const [attest, setAttest] = useState([]);
  const [searchcol, setSearchcol] = useState("");
  const [userslist, setUserslist] = useState([]);
  const [user, setUser] = useState([]);
  const [client, setClientlist] = useState([]);

  useEffect(() => {
    setLoading(false);
  });

  useEffect(
    (notyf) => {
      Axios.get("/api/get_attest").then((response) => {
        setAttest(response.data);
      });

      Axios.get("/api/get_users").then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setUserslist(response.data);
        }
      });

      Axios.get("/api/get_clients").then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setClientlist(response.data);
        }
      });

      setLoading(true);
    },
    [setLoading]
  );

  const handleChangeCol = (value) => {
    setSearchcol(value);

    const user = userslist.sort((a, b) => (a.lastname > b.lastname ? 1 : -1));
    setUser(user);
  };

  const onSearch = (value) => {
    setUserslist(value);
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const clientslist = client.sort((a, b) => (a.nom > b.nom ? 1 : -1));

  const filteredType = attest.reduce(
    (unique, item) =>
      unique.includes(item.type) ? unique : [...unique, item.type],
    []
  );

  const [form] = Form.useForm();

  return (
    <main className="main article">
      <Form {...layout} name="control-ref" form={form}>
        <div className="container flex admin">
          <div className="card01 m-2">
            <h4><b>LISTE DES TITRES D'HABILITATIONS</b></h4>
            {loading === true ? (
              <LoadAnim size="large" />
            ) : (
              <div className="card2 flex">
                <Form.Item
                  label="Sélectionner une option de tri"
                  style={{
                    margin: "0.5rem",
                  }}
                >
                  <Select
                    placeholder="Select By:"
                    onChange={handleChangeCol}
                    style={{ width: 250, marginRight: 10 }}
                  >
                    <Option value="userid">NOM</Option>
                    <Option value="data1">CLIENT</Option>
                    <Option value="type">TYPE</Option>
                    <Option value="tous">TOUS...</Option>
                  </Select>
                </Form.Item>
                <>
                  {searchcol === "userid" ? (
                    <Form.Item
                      name="userid"
                      style={{
                        margin: "0.5rem",
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select a name"
                        onChange={onSearch}
                        style={{ width: 200, marginRight: 10 }}
                      >
                        {user.map((val, index) => (
                          <Select.Option key={index} value={val.lastname}>
                            {val.lastname}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                  {searchcol === "data1" ? (
                    <Form.Item
                      name="data1"
                      style={{
                        margin: "0.5rem",
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select a factory"
                        onChange={onSearch}
                        style={{ width: 200, marginRight: 10 }}
                      >
                        {clientslist.map((val, index) => (
                          <Select.Option
                            key={index}
                            value={val.nom + " " + val.lieu}
                          >
                            {val.nom + " " + val.lieu}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                  {searchcol === "type" ? (
                    <Form.Item
                      name="type"
                      style={{
                        margin: "0.5rem",
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select a type"
                        onChange={onSearch}
                        style={{ width: 200, marginRight: 10 }}
                      >
                        {filteredType.map((val, index) => (
                          <Select.Option key={index} value={val}>
                            {val}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                </>
              </div>
            )}
            <div className="card1 flex app">
              <ul>
                <li className="flex">
                  <div className="mx-1 w-1 smb">NOM</div>
                  <div className="mx-1 w-2 smb">DESIGNATION</div>
                  <div className="mx-1 w-4 smb">TITRE / CLIENT</div>
                  <div className="mx-1 w-1 smb">VALIDITE</div>
                </li>
              </ul>
              <ul>
                {attest
                  .filter((val) => {
                    if (
                      searchcol === "userid" &&
                      userslist.includes(val.userid)
                    ) {
                      if (userslist !== "") {
                        return val;
                      }
                    } else if (
                      searchcol === "data1" &&
                      userslist.includes(val.data1)
                    ) {
                      if (userslist !== "") {
                        return val;
                      }
                    } else if (
                      searchcol === "type" &&
                      userslist.includes(val.type)
                    ) {
                      if (userslist !== "") {
                        return val;
                      }
                    } else if (searchcol === "tous") {
                      if (userslist !== "") {
                        return val;
                      }
                    }
                    return false;
                  })
                  .sort((a, b) => (a.userid > b.userid ? 1 : -1))
                  .map((val, index) => {
                    var now = moment();
                    var nowAlarm = moment().add(90, "d");
                    var valid = val.valid;
                    var dateLimit = moment(valid);

                    return (
                      <div className="card1 flex app" key={index}>
                        <ul className="mb-0">
                          <li className="flex bor-1 pt-1">
                            <div className="mx-1 w-1">{val.userid}</div>
                            <div className="mx-1 w-2">{val.type}</div>
                            <div className="mx-1 w-4">{val.data1}</div>
                            {dateLimit <= now ? (
                              <div className="mx-1 w-1 limitAlert">
                                {dateLimit.format("DD-MM-YYYY")}
                              </div>
                            ) : (
                              <></>
                            )}
                            {dateLimit >= now && dateLimit <= nowAlarm ? (
                              <div className="mx-1 w-1 limitAlarm">
                                {dateLimit.format("DD-MM-YYYY")}
                              </div>
                            ) : (
                              <></>
                            )}
                            {dateLimit >= nowAlarm ? (
                              <div className="mx-1 w-1 limit">
                                {dateLimit.format("DD-MM-YYYY")}
                              </div>
                            ) : (
                              <></>
                            )}
                          </li>
                        </ul>
                      </div>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </Form>
      {/* <cercle/> */}
    </main>
  );
}

export default Clearances;
