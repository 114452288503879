import React from "react";

import { Button, Form, Input, Select, Space } from "antd";

import moment from "moment";

const { Search } = Input;
const { Option } = Select;

export default function CardGestion({
  loading,
  LoadAnim,
  searchcol,
  searchspie,
  searchsn,
  onSearch,
  company,
  user,
  onSearchsn,
  access,
  idlist,
  btInfo,
  btStock,
  btUpdate,
  btDelete,
  handleChangeCol,
  valitem,
  btAjout,
  designation,
}) {
  const idlistt = idlist || [];
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const [form] = Form.useForm();

  return (
    <Form {...layout} name="control-ref" form={form}>
      <div className="container flex admin">
        <div className="card01 m-2">
          <h4><b>GESTION DE L'OUTILLAGE</b></h4>
          {loading === true ? (
            <LoadAnim size="large" />
          ) : (
            <div className="card2 flex">
              <Form.Item
                name="tri"
                label="Sélectionner une option de tri"
                style={{
                  margin: "0.5rem",
                }}
              >
                <Select
                  placeholder="Select by:"
                  onChange={handleChangeCol}
                  style={{ width: 250, marginRight: 10 }}
                >
                  <Option value="spie">N° ID SPIE</Option>
                  <Option value="num">FABRICANT</Option>
                  <Option value="design">DESIGNATION</Option>
                  <Option value="userid">ATTRIBUE A</Option>
                  <Option value="sn">NUM. de SERIE</Option>
                </Select>
              </Form.Item>
              <>
                {searchcol === "spie" ? (
                  <Form.Item
                    name="spie"
                    style={{
                      margin: "0.5rem",
                    }}
                  >
                    <Search
                      placeholder="input search"
                      allowClear
                      enterButton="search"
                      size="middle"
                      onSearch={onSearch}
                      style={{ width: 250 }}
                    />
                  </Form.Item>
                ) : (
                  <></>
                )}
                {searchcol === "num" ? (
                  <Form.Item
                    name="num"
                    style={{
                      margin: "0.5rem",
                    }}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a factory"
                      onChange={onSearch}
                      style={{ width: 200, marginRight: 10 }}
                    >
                      {company.map((val, index) => (
                        <Select.Option key={index} value={val.num}>
                          {val.num}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <></>
                )}
                {searchcol === "userid" ? (
                  <Form.Item
                    name="userid"
                    style={{
                      margin: "0.5rem",
                    }}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a name"
                      onChange={onSearch}
                      style={{ width: 200, marginRight: 10 }}
                    >
                      {user.map((val, index) => (
                        <Select.Option key={index} value={val.lastname}>
                          {val.lastname}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <></>
                )}
                {searchcol === "design" ? (
                  <Form.Item
                    name="design"
                    style={{
                      margin: "0.5rem",
                    }}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select une désignation"
                      onChange={onSearch}
                      style={{ width: 200, marginRight: 10 }}
                    >
                      {designation.map((val, index) => (
                        <Select.Option key={index} value={val.design}>
                          {val.design}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <></>
                )}
                {searchcol === "sn" ? (
                  <Form.Item
                    name="sn"
                    style={{
                      margin: "0.5rem",
                    }}
                  >
                    <Search
                      placeholder="input search"
                      allowClear
                      enterButton="searchsn"
                      size="middle"
                      onSearch={onSearchsn}
                      style={{ width: 250 }}
                    />
                  </Form.Item>
                ) : (
                  <></>
                )}
              </>
              {access > 3 ? (
                <Space wrap>
                  <Button
                    onClick={() => {
                      btAjout(valitem);
                    }}
                  >
                    Ajout outils
                  </Button>
                </Space>
              ) : (
                <></>
              )}
            </div>
          )}
          <div className="card1 flex app">
            <ul>
              <li className="flex">
                {access === 6 ? (
                  <>
                    <div className="mx-0.5 w-1 smb">ID</div>
                  </>
                ) : (
                  <></>
                )}
                <div className="mx-0.5 w-1 smb">N°</div>
                <div className="mx-0.5 w-1 smb">MARQUE</div>
                <div className="mx-0.5 w-1 smb">TYPE</div>
                <div className="mx-0.5 w-1 smb">NUM. SERIE</div>
                <div className="mx-1 w-1 smb">VALIDE</div>
                <div className="mx-1 w-1 smb">ATTRIBUE A</div>
                <div className="mx-2 smb"></div>
                {access > 0 ? (
                  <>
                    <div className="mx-1">.</div>
                  </>
                ) : (
                  <></>
                )}
                {access > 3 ? (
                  <>
                    <div className="mx-1">.</div>
                    <div className="mx-1">.</div>
                    <div className="mx-0.5">.</div>
                  </>
                ) : (
                  <></>
                )}
                {access > 5 ? (
                  <>
                    <div className="mx-2">.</div>
                  </>
                ) : (
                  <></>
                )}
              </li>
            </ul>
            {/* Tableau final */}
            {idlistt
              .filter((val) => {
                if (
                  searchcol === "spie" &&
                  val.spie
                    .toLocaleLowerCase()
                    .includes(searchspie.toLocaleLowerCase())
                ) {
                  if (searchspie !== "") {
                    return val;
                  }
                } else if (
                  searchcol === "num" &&
                  val.num
                    .toLocaleLowerCase()
                    .includes(searchspie.toLocaleLowerCase())
                ) {
                  if (searchspie !== "") {
                    return val;
                  }
                } else if (
                  searchcol === "design" &&
                  val.design
                    .toLocaleLowerCase()
                    .includes(searchspie.toLocaleLowerCase())
                ) {
                  if (searchspie !== "") {
                    return val;
                  }
                } else if (
                  searchcol === "userid" &&
                  val.userid
                    .toLocaleLowerCase()
                    .includes(searchspie.toLocaleLowerCase())
                ) {
                  if (searchspie !== "") {
                    return val;
                  }
                } else if (
                  searchcol === "sn" &&
                  val.sn
                    .toLocaleLowerCase()
                    .includes(searchsn.toLocaleLowerCase())
                ) {
                  if (searchsn !== "") {
                    return val;
                  }
                }
                return false;
              })
              .sort((a, b) => (a.spie > b.spie ? 1 : -1))
              .map((val, index) => {
                var now = moment();
                var nowAlarm = moment().add(90, "d");
                var valid = val.rev;
                var dateLimit = moment(valid);

                return (
                  <div className="card1 flex app" key={index}>
                    <ul className="mb-0">
                      <li className="flex bor-1">
                        {access === 6 ? (
                          <>
                            <div className="mx-0.5 w-1">{val.id}</div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="mx-0.5 w-1 py-1">{val.spie}</div> {/* Numéro Spie */}
                        <div className="mx-0.5 w-1">{val.num}</div> {/* Marque */}
                        <div className="mx-0.5 w-1">{val.ref}</div> {/* type */}
                        <div className="mx-0.5 w-1">{val.sn}</div> {/* N° de série */}
                        {dateLimit <= now ? (
                          <div className="mx-1 w-1 limitAlert">
                            {dateLimit.format("DD-MM-YYYY")}
                          </div>
                        ) : (
                          <></>
                        )}
                        {dateLimit >= now && dateLimit <= nowAlarm ? (
                          <div className="mx-1 w-1 limitAlarm">
                            {dateLimit.format("DD-MM-YYYY")}
                          </div>
                        ) : (
                          <></>
                        )}
                        {dateLimit >= nowAlarm ? (
                          <div className="mx-1 w-1 limit">
                            {dateLimit.format("DD-MM-YYYY")}
                          </div>
                        ) : (
                          <></>
                        )}
                        {val.userid === "HORS-ST" ||
                        val.userid === "NON AFFECTE" ? (
                          <div className="mx-1 w-1 limitAlert">
                            {val.userid}
                          </div>
                        ) : (
                          <div className="mx-1 w-1 limit">{val.userid}</div>
                        )}
                        <Space wrap>
                          <Button
                            onClick={() => {
                              btInfo(val);
                            }}
                          >
                            Info
                          </Button>
                          {access > 0 ? (
                            <>
                              <Button
                                type="primary"
                                size="small"
                                ghost
                                onClick={() => {
                                  btStock(val);
                                }}
                              >
                                Stock
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                          {access > 3 ? (
                            <>
                              <Button
                                type="primary"
                                size="small"
                                ghost
                                onClick={() => {
                                  btUpdate(val);
                                }}
                              >
                                Update
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                          {access > 5 ? (
                            <>
                              <Button
                                type="primary"
                                size="small"
                                danger
                                ghost
                                onClick={() => {
                                  btDelete(val);
                                }}
                              >
                                Delete
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                        </Space>
                      </li>
                      {/* <li className="flex">
                                                {loanlist.filter((value) => {
                                                    if (val.id === value.outils_id) {
                                                        return (value)
                                                    }
                                                    return false
                                                }).map((value, i, row, index) => {
                                                    if  (i + 1 === row.length && val.userid === 'HORS-ST'){
                                                        return (
                                                            <>
                                                            <div className="mx-1 w-1">.</div>
                                                            <div className="mx-1 w-1">.</div>
                                                            <div className="mx-1 w-1">.</div>
                                                            <div className="mx-1 w-1" key={index}>{value.obs.substr(0,8)}</div>
                                                            </>
                                                        )}
                                                    return false
                                                })
                                                }
                                            </li> */}
                    </ul>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Form>
  );
}
