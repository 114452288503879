import { Button, Form, Input, Select } from "antd";
import React from "react";

export default function CardAddMdp({
  onFinish,
  onFinishFailed,
  onNumChange,
  company,
  setButtonPopup,
}) {
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const onReset = () => {
    form.resetFields();
    setButtonPopup(false);
  };

  const [form] = Form.useForm();

  return (
    <Form
      {...layout}
      name="AddMdp"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <main className="popup">
        <div className="popup-inner">
          <div className="card01">
            <h4>AJOUTER UN ELEMENT</h4>
            <div className="card1 flex">
              <table>
                <thead>
                  <tr>
                    <th>
                      <h3>Marque</h3>
                    </th>
                    <th>
                      <h3>Appareil</h3>
                    </th>
                    <th>
                      <h3>Type</h3>
                    </th>
                    <th>
                      <h3>Utilisateur</h3>
                    </th>
                    <th>
                      <h3>Mot de Passe</h3>
                    </th>
                    <th>
                      <h3>Site</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Item
                        name="marque"
                        style={{
                          margin: "0.5rem",
                        }}
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          placeholder="Marque / Fourn."
                          onChange={onNumChange}
                        >
                          {company.map((val, index) => (
                            <Select.Option key={index} value={val.num}>
                              {val.num}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="appareil"
                        style={{
                          margin: "0.5rem",
                        }}
                        rules={[{ required: true }]}
                      >
                        <Input type="text" name="appareil" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="type"
                        style={{
                          margin: "0.5rem",
                        }}
                        rules={[{ required: true }]}
                      >
                        <Input type="text" name="type" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="iduser"
                        style={{
                          margin: "0.5rem",
                        }}
                      >
                        <Input type="text" maxLength={20} name="iduser" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="passwordadd"
                        style={{
                          margin: "0.5rem",
                        }}
                        rules={[{ required: true }]}
                      >
                        <Input type="text" name="passwordadd" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="site"
                        style={{
                          margin: "0.5rem",
                        }}
                        rules={[{ required: true }]}
                      >
                        <Input type="text" name="site" />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Form.Item
                style={{
                  margin: "0.5rem",
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
              <div className="close-btn">
                <Form.Item>
                  <Button htmlType="button" size="small" onClick={onReset}>
                    X
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Form>
  );
}
