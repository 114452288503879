import { Button, DatePicker, Form, Input, Select, Space } from "antd";
import React from "react";

const { Option } = Select;

export default function CardUpdate({
  handleChangeUpdateId,
  updateid,
  update,
  setUpdate,
  onSetRevChange,
  handleChangeUser,
  userslist,
  btupdateId,
  valitem,
  onReset,
}) {
  const [form] = Form.useForm();
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  return (
    <Form {...layout} name="control-ref" form={form}>
      <div className="popup">
        <div className="popup-inner">
          <div className="card01">
            <h4>Mise à jour Item</h4>
            <div className="card11 flex">
              <div className="flex padtop">
                <Form.Item>
                  <Select
                    showSearch
                    placeholder="Select Option"
                    onChange={handleChangeUpdateId}
                    style={{ width: 250, marginRight: 10 }}
                  >
                    <Option value="spie">N° Spie</Option>
                    <Option value="num">Marque</Option>
                    <Option value="ref">Type</Option>
                    <Option value="design">Désignation</Option>
                    <Option value="obs">Commentaire</Option>
                    <Option value="sn">N° de série</Option>
                    <Option value="rev">Validitée</Option>
                    <Option value="userid">Attribué à:</Option>
                  </Select>
                </Form.Item>
                <Space>
                  {updateid === "spie" ||
                  updateid === "num" ||
                  updateid === "ref" ||
                  updateid === "design" ||
                  updateid === "obs" ||
                  updateid === "sn" ? (
                    <Form.Item name="texte">
                      <Input
                        placeholder="Texte"
                        value={update}
                        onChange={(e) => {
                          setUpdate(e.target.value);
                        }}
                      />
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                  {updateid === "rev" ? (
                    <Form.Item
                      name="rev"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormatList}
                        onChange={onSetRevChange}
                      />
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                  {updateid === "userid" ? (
                    <Form.Item name="userid" rules={[{ required: true }]}>
                      <Select
                        showSearch
                        placeholder="Select a name"
                        onChange={handleChangeUser}
                        style={{ width: 200, marginRight: 10 }}
                      >
                        {userslist.map((val, index) => (
                          <Select.Option key={index} value={val.id}>
                            {val.lastname}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                  <Form.Item>
                    <Button
                      type="primary"
                      size="small"
                      ghost
                      onClick={() => {
                        btupdateId(valitem.id);
                      }}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Space>
              </div>
            </div>
            <div className="close-btn">
              <Form.Item>
                <Button htmlType="button" size="small" onClick={onReset}>
                  X
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}
