import { Button, Form } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "../../../node_modules/antd/dist/antd.min.css";
import "../../../src/style.css";
import { Axios } from "../../config";
import CardAttestList from "./CardAttestList";
import CardMdp from "./CardMdp";
import CardTraining from "./CardTraining";

import { AdminContext } from "../../Context/AdminContext";
import NotyfContext from "../../Context/NotyfContext";

export default function Profile() {
  const notyf = useContext(NotyfContext);
  const { username, idlogged, access } = useContext(AdminContext);

  const [client, setClientlist] = useState([]);
  const [attest, setAttest] = useState([]);
  const [form] = Form.useForm();
  const [typeform, setTypeForm] = useState("");
  const [traininglist, setTrainingList] = useState([]);
  const [buttonpopupattest, setButtonPopupAttest] = useState(false);
  const [buttonpopupmdp, setButtonPopupMdp] = useState(false);
  const [attestid, setAttestId] = useState("");
  const [userslist, setUserslist] = useState([]);
  const [idusername, setIdUsername] = useState(idlogged);

  const listclient = (notyf) => {
    Axios.get("/api/get_clients").then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setClientlist(response.data);
      }
    });
  };

  const listattest = (notyf) => {
    Axios.get("/api/get_attest").then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setAttest(response.data);
      }
    });
  };

  const listusers = (notyf) => {
    Axios.get("/api/get_users").then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setUserslist(response.data);
      }
    });
  };

  useEffect(() => {
    listclient();
    listattest();
    listusers();
  }, []);

  useEffect(() => {
    listattest();
  }, [traininglist]);

  useEffect(() => {
    if (idusername === "") {
      setIdUsername(idlogged);
    }
  }, [setIdUsername, idlogged, idusername]);

  const chgPassword = async (values) => {
    await Axios.put("/api/updateusers", {
      lastname: username,
      password: values.newpassword,
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        form.resetFields();
        onReset();
        notyf.success(`Mode de passe est modifié`);
        setButtonPopupMdp(false);
      }
    });
  };

  const onFinishFailed = (errorFields) => {
    if (errorFields) {
      notyf.open({ type: "error", message: `${errorFields}` });
    }
  };

  const onReset = () => {
    form.resetFields();
    setButtonPopupMdp(false);
    setButtonPopupAttest(false);
  };
  // Card
  const btinsertTraining = async (data) => {
    if (
      data.type === "Habilitation Electrique" ||
      data.type === "Formation" ||
      data.type === "Spécifique site" ||
      data.type === "Offshore" ||
      data.type === "Attestation Santé" ||
      data.type === "Visa Multiple" ||
      data.type === "Visa Unique" ||
      data.type === "Autorisation de conduite"
    ) {
      await Axios.post("/api/insertattest", {
        userid: idusername,
        type: data.type,
        valid: data.valid.format("YYYY-MM-DD"),
        data1: data.data1,
      }).then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setTrainingList([
            ...traininglist,
            {
              userid: username,
              type: typeform,
              valid: data.valid.format("YYYY-MM-DD"),
              data1: data.data1,
            },
          ]);
          setButtonPopupAttest(false);
          notyf.success(response.data.message);
        }
      });
    }
  };

  const btupdateValid = async (data) => {
    await Axios.put("/api/updatevalid", {
      id: attestid,
      updatevalid: data.valid.format("YYYY-MM-DD"),
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        form.resetFields();
        notyf.success("Date de validée modifiée");
      }
    });
  };

  const btdeleteAttest = async (id) => {
    await Axios.delete(`/api/delete_attest/${id}`).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        notyf.success("Item supprimé de la BDD");
      }
    });
  };

  return (
    <main className="main article">
      <div className="container flex admin">
        <div className="card01 flex row m-2">
          <div className="card01">
            <Button onClick={() => setButtonPopupMdp(true)}>
              {" "}
              Changer son Mot de passe
            </Button>
          </div>
          {access > 2 || access === 1 ? (
            <>
              <div className="card01">
                <Button onClick={() => setButtonPopupAttest(true)}>
                  Ajouter une formation
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {/* Changer Mot de passe */}
        {buttonpopupmdp === true ? (
          <CardMdp
            onReset={onReset}
            onFinishFailed={onFinishFailed}
            chgPassword={chgPassword}
            username={username}
          />
        ) : (
          <></>
        )}
        {/* Ajouter une formation */}
        {buttonpopupattest === true ? (
          <CardTraining
            onReset={onReset}
            onFinishFailed={onFinishFailed}
            onFinish={btinsertTraining}
            typeform={typeform}
            setTypeForm={setTypeForm}
            client={client}
            setClientlist={setClientlist}
            userslist={userslist}
            username={username}
            access={access}
            setIdUsername={setIdUsername}
          />
        ) : (
          <></>
        )}
        {/* liste des attestations */}
        {access > 2 || access === 1 ? (
          <>
            <CardAttestList
              attest={attest}
              setAttestId={setAttestId}
              onFinishFailed={onFinishFailed}
              btupdateValid={btupdateValid}
              btdeleteAttest={btdeleteAttest}
              access={access}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </main>
  );
}
