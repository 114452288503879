import React from "react";
import image from "../../../src/Komsilga.jpg";

export default function Home() {
  return (
    <main className="main article">
      <img src={image} width="100%" alt="Centrâle de Komsilga Burkina Faso" />
    </main>
  );
}
