import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import AdminContextProvider from "./Context/AdminContext";

ReactDOM.render(
  <React.StrictMode>
    <AdminContextProvider>
      <App />
    </AdminContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
