import { Button, Form } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "../../../node_modules/antd/dist/antd.min.css";
import "../../../src/style.css";
import { Axios } from "../../config";
import CardAjoutUtil from "./CardAjoutUtil";
import CardNivAccess from "./CardNivAccess";
import CardReinitMdp from "./CardReinitMdp";
import Employed from "./Employed";

import { AdminContext } from "../../Context/AdminContext";

import NotyfContext from "../../Context/NotyfContext";

export default function Admin() {
  const { access } = useContext(AdminContext);

  const [updatecde, setUpdateCde] = useState("");
  const [userslist, setUserslist] = useState([]);
  const [buttonpopupajoututil, setButtonPopupAjoutUtil] = useState(false);
  const [buttonpopupreinitmdp, setButtonPopupReinitMdp] = useState(false);
  const [buttonpopupnivaccess, setButtonPopupNivAccess] = useState(false);
  const [error, setError] = useState("");
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    Axios.get("/api/get_users")
      .then((response) => {
        setUserslist(response.data);
        setUpdateCde("0");
      })
      .catch((err) => {
        setError(err);
      });
    }, [updatecde, error]);
    
    const onFinishAjoutUtil = async (values) => {
    await Axios.post("/api/insertusers", {
      lastname: values.lastname,
      firstname: values.firstname,
      email: values.email,
      password: values.password,
      access: values.access,
    }).then(
      setUserslist([
        ...userslist,
        {
          lastname: values.lastname,
          firstname: values.firstname,
          email: values.email,
          password: values.password,
          access: values.access,
        },
      ]),
      setUpdateCde("1"),
      setButtonPopupAjoutUtil(false),
      notyf.open({ type: "success", message: "Created" }),
    );
  };

  const onFinishReinitMdp = async (value) => {
    await Axios.put("/api/updatereinitmdp", {
      id: value.useridmdp,
      password: 123456,
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setButtonPopupReinitMdp(false);
        notyf.success(`Mot de passe changé`);
      }
    });
  };

  const onFinishNivAccess = async (value) => {
    await Axios.put("/api/updatenivaccess", {
      id: value.userid,
      access: value.access,
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setButtonPopupNivAccess(false);
        notyf.success(`Niveau modifié`);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setError(errorInfo).then(
      notyf.open({ type: "error", message: `${errorInfo}` })
    );
  };

  // const user =  () => {userslist.sort((a,b) => a.lastname > b.lastname ? 1 : -1)
  //   setUsername(user)
  // }

  const handleChangeColReinitMdp = () => {};

  const handleChangeColNivAccess = () => {};

  const onSearch = (value) => {
    // setSearchspie(value)
    form.resetFields();
  };

  // const onReset = () => {
  //   form.resetFields();
  // };
  const [form] = Form.useForm();

  return (
    <main className="main article">
      <div className="container flex admin">
        <div className="card01 flex row m-2">
          <div className="card01">
            <Button onClick={() => setButtonPopupAjoutUtil(true)}>
              Ajouter un Utilisateur
            </Button>
          </div>
          <div className="card01">
            <Button onClick={() => setButtonPopupReinitMdp(true)}>
              Réinitialiser MDP
            </Button>
          </div>
          {access > 5 ? (
            <div className="card01">
              <Button onClick={() => setButtonPopupNivAccess(true)}>
                Modification Niveau Acces
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
        {buttonpopupajoututil === true ? (
          <CardAjoutUtil
            userslist={userslist}
            setButtonPopup={setButtonPopupAjoutUtil}
            handChangeCol={handleChangeColReinitMdp}
            onFinish={onFinishAjoutUtil}
            onFinishFailed={onFinishFailed}
            onSearch={onSearch}
          />
        ) : (
          <></>
        )}
        {buttonpopupreinitmdp === true ? (
          <CardReinitMdp
            userslist={userslist}
            setButtonPopup={setButtonPopupReinitMdp}
            handChangeCol={handleChangeColReinitMdp}
            onFinish={onFinishReinitMdp}
            onFinishFailed={onFinishFailed}
            onSearch={onSearch}
          />
        ) : (
          <></>
        )}
        {buttonpopupnivaccess === true ? (
          <CardNivAccess
            userslist={userslist}
            setButtonPopup={setButtonPopupNivAccess}
            handleChangeCol={handleChangeColNivAccess}
            onFinish={onFinishNivAccess}
            onFinishFailed={onFinishFailed}
            onSearch={onSearch}
          />
        ) : (
          <></>
        )}
      </div>
      <Employed></Employed>
    </main>
  );
}
