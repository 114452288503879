import { Form, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "../../../src/style.css";
import { Axios } from "../../config";

import { AdminContext } from "../../Context/AdminContext";
import LoadAnim from "../LoadAnim";

const { Option } = Select;

function Codes() {
  const { loading, setLoading } = useContext(AdminContext);
  const [ansi, setAnsi] = useState([]);
  const [searchcol, setSearchcol] = useState("");
  const [ansilist, setAnsilist] = useState("");
  const [iec61850list, setIec61850list] = useState("");

  useEffect(() => {
    setLoading(false);
  });

  useEffect(
    (notyf) => {
      Axios.get("/api/get_ansi").then((response) => {
        setAnsi(response.data);
      });
      setLoading(true);
    },
    [setLoading]
  );

  const handleChangeCol = (value) => {
    setSearchcol(value);
  };

  const onSearch = (value) => {
    setAnsilist(value);
    setIec61850list(value);
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const [form] = Form.useForm();

  return (
    <main className="main article">
      <Form {...layout} name="control-ref" form={form}>
        <div className="container flex admin">
          <div className="card01 m-2">
            <h4><b>LISTE DES CODES</b></h4>
            {loading === true ? (
              <LoadAnim size="large" />
            ) : (
              <div className="card2 flex">
                <Form.Item
                  label="Sélectionner une option de tri"
                  style={{
                    margin: "0.5rem",
                  }}
                >
                  <Select
                    placeholder="Select By:"
                    onChange={handleChangeCol}
                    style={{ width: 250, marginRight: 10 }}
                  >
                    <Option value="ansi">ANSI</Option>
                    <Option value="iec61850">IEC61850</Option>
                    <Option value="tous">TOUS...</Option>
                  </Select>
                </Form.Item>
                <>
                  {searchcol === "ansi" ? (
                    <Form.Item
                      name="ansi"
                      style={{
                        margin: "0.5rem",
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select a code"
                        onChange={onSearch}
                        style={{ width: 200, marginRight: 10 }}
                      >
                        {ansi.map((val, index) => (
                          <Select.Option key={index} value={val.ansi}>
                            {val.ansi}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                  {searchcol === "iec61850" ? (
                    <Form.Item
                      name="iec61850"
                      style={{
                        margin: "0.5rem",
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select a code"
                        onChange={onSearch}
                        style={{ width: 200, marginRight: 10 }}
                      >
                        {ansi.map((val, index) => (
                          <Select.Option key={index} value={val.iec61850}>
                            {val.iec61850}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                </>
              </div>
            )}
            <div className="card1 flex app">
              <ul>
                <li className="flex">
                  <div className="mx-1 w-1 smb">ANSI</div>
                  <div className="mx-1 w-1 smb">IEC61850</div>
                  <div className="mx-1 w-1 smb">IEC60617</div>
                  <div className="mx-1 w-3 smb">DESIGNATION FR</div>
                  <div className="mx-1 w-3 smb">DESIGNATION EN</div>
                </li>
              </ul>
              {ansi
                .filter((val) => {
                  if (searchcol === "ansi" && ansilist.includes(val.ansi))
                    if (ansilist !== "") {
                      return val;
                    }
                  if (
                    searchcol === "iec61850" &&
                    iec61850list.includes(val.iec61850)
                  )
                    if (iec61850list !== "") {
                      return val;
                    }
                  if (searchcol === "tous") {
                    return val;
                  }
                  return false;
                })
                .map((val, index) => {
                  return (
                    <div className="card1 flex app" key={index}>
                      <ul className="mb-0">
                        <li className="flex bor-1">
                          <div className="mx-1 w-1">{val.ansi}</div>
                          <div className="mx-1 w-1">{val.iec61850}</div>
                          <div className="mx-1 w-1">{val.iec60617}</div>
                          <div className="mx-1 w-3">{val.designFr}</div>
                          <div className="mx-1 w-3">{val.designEn}</div>
                        </li>
                      </ul>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default Codes;
