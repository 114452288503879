import { Button, Form, Input, Select } from "antd";
import React from "react";
import "../../../node_modules/antd/dist/antd.min.css";
import "../../../src/style.css";

import { MailOutlined, UserOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function CardAjoutUtil({
  onFinish,
  onFinishFailed,
  setButtonPopup,
  handleChangeCol,
}) {
  const onReset = () => {
    form.resetFields();
    setButtonPopup(false);
  };

  const [form] = Form.useForm();

  return (
    <main className="popup">
      <div className="popup-inner">
        <div className="card01">
          <h4>AJOUTER UN UTILISATEUR</h4>
          <div className="card11 flex">
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Nom"
                name="lastname"
                rules={[{ required: true }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Last Name"
                  value="Last Name"
                  maxLength={20}
                ></Input>
              </Form.Item>
              <Form.Item
                label="Prénom"
                name="firstname"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="First Name"
                  value="First Name"
                  maxLength={20}
                ></Input>
              </Form.Item>
              <Form.Item
                label="Courriel Spie"
                name="email"
                rules={[{ required: true }, { type: "email" }]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  value="Email"
                  maxLength={45}
                ></Input>
              </Form.Item>
              <Form.Item
                label="Mot de passe"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="access"
                label="Niveau"
                style={{
                  margin: "0.5rem",
                }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select a level"
                  onChange={handleChangeCol}
                  style={{ width: 200, marginRight: 10 }}
                >
                  <Option value="0">Accès limité</Option>
                  <Option value="1">Utilisateurs Serres Castet</Option>
                  <Option value="2">Utilisateurs Autres</Option>
                  <Option value="3">Tech. Mise en service</Option>
                  <Option value="4">Encadrement</Option>
                  <Option value="5">Administrateur</Option>
                </Select>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Ajouter
                </Button>
                <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="close-btn">
            <Form.Item>
              <Button htmlType="button" size="small" onClick={onReset}>
                X
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </main>
  );
}
