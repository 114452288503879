import { Notyf } from "notyf";
import React from "react";
import "../../node_modules/notyf/notyf.min.css";

export default React.createContext(
  new Notyf({
    duration: 3000,
    position: {
      x: "center",
      y: "top",
    },
    types: [
      {
        type: "warning",
        background: "orange",
        duration: 3000,
        icon: {
          className: "material-icons",
          tagName: "i",
          text: "warning",
          duration: 3000,
        },
      },
      {
        type: "error",
        background: "red",
        duration: 3000,
        icon: {
          className: "material-icons",
          tagName: "i",
          text: "warning",
          duration: 3000,
        },
      },
    ],
  })
);
