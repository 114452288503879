import { Form, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "../../../src/style.css";
import { Axios } from "../../config";

import { AdminContext } from "../../Context/AdminContext";
import LoadAnim from "../LoadAnim";

function Employed() {
  const { loading, setLoading } = useContext(AdminContext);
  const [userslist, setUserslist] = useState([]);

  useEffect(() => {
    setLoading(false);
  });

  useEffect(
    (notyf) => {
      Axios.get("/api/get_users").then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setUserslist(response.data);
        }
      });

      setLoading(true);
    },
    [setLoading]
  );

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const [form] = Form.useForm();

  return (
    <main className="main article">
      <Form {...layout} name="control-ref" form={form}>
        <div className="container flex admin">
          <div className="card01 m-2">
            <h4><b>LISTE DES EMPLOYES</b></h4>
            {loading === true ? (
              <LoadAnim size="large" />
            ) : (
                <>
                        {userslist
                          .sort((a, b) => (a.lastname > b.lastname ? -1 : 1))
                          .map((val, index) => (
                          <Select.Option key={index} value={val.lastname}>
                            {val.lastname}
                          </Select.Option>
                        ))}
                </>
            )}
            <div className="card1 flex app">
              <ul>
                <li className="flex">
                <div className="mx-1 w-1 smb">ID</div>
                  <div className="mx-1 w-1 smb">NOM</div>
                  <div className="mx-1 w-1 smb">PRENOM</div>
                  <div className="mx-1 w-1 smb">COURRIEL</div>
                  <div className="mx-1 w-0 smb">.</div>
                  <div className="mx-1 w-1 smb">NIVEAU ACCES</div>
                  <div className="mx-1 w-1 smb">DATE CREATION</div>
                </li>
              </ul>
              <ul>
                {userslist
                  .sort((a, b) => (a.userid > b.userid ? 1 : -1))
                  .map((val, index) => {
                    return (
                      <div className="card1 flex app" key={index}>
                        <ul className="mb-0">
                          <li className="flex bor-1 pt-1">
                          <div className="mx-1 w-1">{val.id}</div>
                            <div className="mx-1 w-1">{val.lastname}</div>
                            <div className="mx-1 w-1">{val.firstname}</div>
                            <div className="mx-1 w-1">{val.email}</div>
                            <div className="mx-1 w-0">.</div>
                            <div className="mx-1 w-1">{val.access}</div>
                            <div className="mx-1 w-1">{val.create_time}</div>
                          </li>
                        </ul>
                      </div>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default Employed;
